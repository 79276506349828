import React from "react";
import { Layout } from "../../components/layout";
import { Color, Variables } from "../../components/designToken";
import circleCheckSvg from "../../images/circleCheck.svg";

const ThanksPage: React.FC<{}> = () => {
  const [subtractionHeight, setSubtractionHeight] = React.useState(0);
  React.useEffect(() => {
    const handleResize = () => {
      const sectionElm = document.querySelector("section.section .sectionInner");
      const sectionStyle = window.getComputedStyle(sectionElm);
      const marginTop = parseInt(sectionStyle["margin-top"].replace("px", ""));
      setSubtractionHeight(
        document.querySelector("footer").clientHeight + document.querySelector(".credit").clientHeight + marginTop
      );
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <style jsx>{`
        .section .sectionInner {
          margin-top: 80px;
          text-align: center;
          min-height: calc(100vh - ${subtractionHeight}px);
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        h1 {
          font-size: 29px;
          color: ${Color.brand};
          font-weight: 500;
          margin: 0 auto 30px;
        }
        h1 span:before {
          content: "";
          position: relative;
          display: inline-block;
          vertical-align: middle;
          width: 65px;
          height: 65px;
          background-image: url(${circleCheckSvg});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 15px;
        }
        h1 span {
          vertical-align: middle;
          line-height: 1;
        }
        p {
          font-size: 19px;
        }
        @media (${Variables.breakPoint.sp}) {
          .section .sectionInner {
            margin-top: 60px;
          }
          h1 {
            font-size: 18px;
          }
          h1 span:before {
            width: 42px;
            height: 42px;
          }
          p {
            font-size: 14px;
          }
        }
      `}</style>
      <Layout>
        <section className="section">
          <div className="sectionInner">
            <div className="centeringInner">
              <h1>
                <span>送信完了</span>
              </h1>
              <p>
                <span>お問い合わせいただき、</span>
                <span>ありがとうございました。</span>
                <br />
                <span>内容を確認次第、</span>
                <span>担当者からご連絡差し上げます。</span>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};
export default ThanksPage;
